<template>
  <div class="contact-frame">
    <div class="is-centered title">
      {{ title }}
    </div>
    <div class="contact-buttons">
      <v-btn
        x-large
        icon
        elevation="0"
        @click="$parent.$parent.$emit('toggleDialog', 'dialog-opening-hours')"
        ><v-icon large>mdi-clock</v-icon></v-btn
      >
      <v-btn
        x-large
        icon
        elevation="0"
        @click="
          $parent.$parent.$emit('toggleDialog', 'dialog-contact-formular')
        "
        ><v-icon large>mdi-email</v-icon></v-btn
      >
      <!-- <v-btn
          icon
          elevation="0"
          @click="$emit('toggleDialog', 'dialog-whatsapp')"
          ><v-icon>mdi-whatsapp</v-icon></v-btn
        > -->

      <!-- <v-btn
        x-large
        icon
        elevation="0"
        @click="$parent.$parent.$emit('toggleDialog', 'dialog-telephone')"
        ><v-icon large>mdi-phone</v-icon></v-btn
      > -->
      <v-btn x-large @click="$router.replace('/contactmap')" icon
        ><v-icon large>mdi-google-maps</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: { title: String },
};
</script>

<style scoped>
.contact-buttons {
  display: flex;
  justify-content: center;
  gap: 0.7rem;
  margin-top: 1rem;
}

.title {
  font-size: large;
  font-weight: normal;
}
</style>